<template>
  <PageLayout>
    <template #header>
      <k-title>{{ user.getFullName() }}</k-title>
    </template>
    <template #flow-actions>
      <v-btn
        color="primary"
        depressed
        tile
        @click="openUpdate"
      >
        {{ $t('actions.updateResource', { resource: $tc('user.title', 1) }) }}
      </v-btn>
      <UserForm
        v-model="updateDialog"
        :intermediary-id="$route.params.intermediaryId"
        :request="updateRequest"
        :title="$tc('user.title',1)"
        :values="updateFormValues"
        is-update-form
        @change="getUser"
      />
    </template>
    <template #default>
      <v-row>
        <v-col md="6">
          <v-card>
            <v-card-text v-if="user">
              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="8"
                >
                  <k-display-group language-prefix="user.fields">
                    <k-text-display field="fullName">
                      {{ user.getFullName() }}
                    </k-text-display>
                    <k-text-display field="zipCode">
                      {{ user.zipCode }}
                    </k-text-display>
                    <k-text-display field="houseNumber">
                      {{ user.houseNumber }}
                    </k-text-display>
                    <k-text-display field="street">
                      {{ user.street }}
                    </k-text-display>
                    <k-text-display field="city">
                      {{ user.city }}
                    </k-text-display>
                    <k-text-display field="country">
                      {{ user.country }}
                    </k-text-display>
                  </k-display-group>
                </v-col>
                <v-col cols="4">
                  <VImg
                    v-if="user.profilePicture"
                    :src="`${user.profilePicture.url}&type=small`"
                    :lazy-src="`${user.profilePicture.url}&type=lazy`"
                    width="200"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </PageLayout>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import User from '@/application/models/User.js';
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import PageLayout from '@/components/layout/PageLayout.vue';
import { show, update } from '@/modules/users/api';
import KTitle from '@/components/layout/KTitle.vue';
import UserForm from '@/modules/users/components/UserForm.vue';

export default {
  name: 'Details',
  components: {
    UserForm,
    KTitle,
    KDisplayGroup,
    PageLayout,
    KTextDisplay,
  },
  props: {
    user: {
      type: Object,
    },
    getUser: {
      type: Function,
    },
    intermediary: {
      type: Object,
    },
  },
  data: () => ({
    updateFormValues: new User(),
    updateDialog: false,
  }),
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'settings.users.show' },
        text: this.user.getFullName(),
      }],
    );
  },
  methods: {
    updateRequest() {
      return update(this.updateFormValues);
    },
    async openUpdate() {
      this.updateFormValues = new User();
      const userShowRequest = await show(this.user.id);
      this.updateFormValues.mapResponse(userShowRequest.data.data);
      this.updateDialog = true;
    },
  },
};
</script>
