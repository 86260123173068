<template>
  <k-field-group language-prefix="user.fields">
    <k-form-dialog
      v-bind="$attrs"
      :panels="[{ name: $t('user.tabs.personal') }]"
      v-on="$listeners"
    >
      <template #panel.0>
        <v-row no-gutters>
          <KTextField
            v-model="values.firstName"
            field="firstName"
            grid="col-sm-4 pr-2"
            required
          />
          <KTextField
            v-model="values.insertion"
            field="insertion"
            grid="col-sm-2 pr-2"
          />
          <KTextField
            v-model="values.lastName"
            field="lastName"
            grid="col-sm-6 pr-2"
            required
          />
          <KTextField
            v-model="values.email"
            field="email"
            grid="col-sm-8 pr-2"
            required
          />
          <KFileField
            v-model="values.profilePicture"
            grid="col-sm-8 pr-2"
            accept="image/*"
            field="profilePicture"
          />
          <IntermediaryRoleAutocomplete
            v-model="values.roles"
            field="roles"
            grid="col-sm-8 pr-2"
            multiple
            required
          />
          <KSelect
            v-model="values.language"
            :items="languages"
            required
            field="language"
            grid="col-sm-8 pr-2"
            item-text="text"
            item-value="locale"
          />
        </v-row>
      </template>
    </k-form-dialog>
  </k-field-group>
</template>

<script>
import KSelect from '@/components/crud/fields/KSelect.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import KFileField from '@/components/crud/fields/KFileField.vue';
import KFormDialog from '@/components/crud/KFormDialog.vue';
import IntermediaryRoleAutocomplete from '@/modules/permissions/components/IntermediaryRoleAutocomplete.vue';

export default {
  name: 'UserForm',
  components: {
    IntermediaryRoleAutocomplete,
    KSelect,
    KFieldGroup,
    KTextField,
    KFileField,
    KFormDialog,
  },
  props: {
    values: {
      type: Object,
    },
  },
  data() {
    return {
      languages: [
        {
          text: this.$t('global.locales.nl'),
          locale: 'nl',
        },
        {
          text: this.$t('global.locales.en'),
          locale: 'en',
        },
      ],
    };
  },
};
</script>
