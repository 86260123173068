import { destroy, get, post, put } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

function indexForEmployer(page, perPage, search, sortBy, descending, params) {
  return getPaginated('employer/role', page, perPage, search, sortBy, descending, params);
}

/**
 * @param role {Role}
 */
function createForEmployer(role) {
  return post('employer/role', role);
}

function permissionsForEmployer() {
  return get('employer/permission');
}

function indexForIntermediary(page, perPage, search, sortBy, descending, params) {
  return getPaginated('intermediary/role', page, perPage, search, sortBy, descending, params);
}

/**
 * @param role {Role}
 */
function createForIntermediary(role) {
  return post('intermediary/role', role);
}

function permissionsForIntermediary() {
  return get('intermediary/permission');
}

/**
 * @param id {number}
 */
function show(id) {
  return get(`role/${id}`);
}

/**
 * @param role {Role}
 */
function update(role) {
  return put(`role/${role.id}`, role);
}

/**
 * @param roleId {number}
 */
function remove(roleId) {
  return destroy(`role/${roleId}`);
}

export {
  indexForIntermediary,
  show,
  createForIntermediary,
  update,
  remove,
  permissionsForIntermediary,
  createForEmployer,
  permissionsForEmployer,
  indexForEmployer,
};
