import Model from '@/application/models/model.js';
import mapFileForRequest from '@/application/util/mapFileForRequest';

class User extends Model {
  id = 0;
  firstName = '';
  insertion = '';
  lastName = '';
  email = '';
  street = '';
  houseNumber = '';
  zipCode = '';
  city = '';
  country = '';
  language = '';
  roles = [];
  profilePicture = null;

  mapForRequest() {
    return {
      ...this,
      profilePicture: mapFileForRequest(this.profilePicture),
      roles: this.roles.map((role) => role.id),
    };
  }

  getFullName() {
    return this.firstName + (this.insertion ? ' ' + this.insertion : '') + (this.lastName ? ' ' + this.lastName : '');
  }

}

export default User;
