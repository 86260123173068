import { get, post, put } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

export const index = (page, perPage, search, sortBy, descending, params) => getPaginated('user', page, perPage, search, sortBy, descending, params);

/**
 * @param user {User}
 */
export const create = (user) => post('user', user);

/**
 * @param id {number}
 */
export const show = (id) => get(`user/${id}`);

/**
 * @param user {User}
 */
export const update = (user) => put(`user/${user.id}`, user);
