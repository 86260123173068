<template>
  <KPaginatedAutocomplete
    ref="autocomplete"
    :paginator="autocomplete"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import KPaginatedAutocomplete from '@/components/crud/fields/KPaginatedAutocomplete.old.vue';
import { indexForIntermediary } from '@/modules/permissions/api/role.js';

export default {
  name: 'IntermediaryRoleAutocomplete',
  components: { KPaginatedAutocomplete },
  methods: {
    /* @TODO this should be an autocomplete not the index */
    autocomplete: indexForIntermediary,
  },
};
</script>
